/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}
const $clone = require('lodash.clone')

module.exports = {
  metadatas: {
    name: 'Sur la Route',
  },
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '6622309dcba11a804b075360',
          i18n: {
            fr: {
              title: 'La minute sécurité',
              description: `Tous les sujets prévention et sécurité à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#E20025',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id == '661fa99bcba11affe00749e7') return true
              if (filter.id == '661fa98fcba11a3dce0749e3') return true
              if (filter.id == '661fd285cba11a3118074ca1') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '662230c6cba11a170607537f',
          i18n: {
            fr: {
              title: `Histoires de compagnons`,
              description: `Tous vos portraits à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#7AA536',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id == '661fa9a6cba11ad3540749eb') return true
              if (filter.id == '661fa9b1cba11a0bb50749ef') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '662230d2cba11ab947075385',
          i18n: {
            fr: {
              title: `En direct des régions`,
              description: `Tous les reportages de vos régions à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#307BFF',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'multiple',
            displayDatePicker: false,
            display(filter, universe) {
              if (filter.id == '661fa9a6cba11ad3540749eb') return true
              if (filter.id == '661fa9b1cba11a0bb50749ef') return true
            },
          },
        },
        {
          type: 'tag',
          tagId: '66eaf3e8f04a4f62214a0d98',
          i18n: {
            fr: {
              title: `Emissions spéciales`,
              description: `Toutes les émissions thématiques à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#F2AB1C',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '67442ce0bc0cab1a7d46c8c3',
          i18n: {
            fr: {
              title: `Dédicaces`,
              description: `Toutes vos dédicaces à réécouter quand vous le souhaitez`,
            },
          },
          heading: {
            color: '#A152E5',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
      ],
    },
  ],
}
