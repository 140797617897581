var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.currentPage === 'default')?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#F0F0F0"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n          Bienvenue sur le site de votre émission de radio quotidienne 100%\n          VINCI Construction ! Au programme : actualités prévention et\n          sécurité au travail, expériences de compagnons de la division Route\n          France, infos, sport, humour, météo.\n          "),_c('br'),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"white--text",attrs:{"color":"#E20025","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpen()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-volume-high ")]),_vm._v("\n            Regarder la vidéo de présentation\n          ")],1),_vm._v(" "),_c('v-btn',{ref:"btn",staticClass:"white--text",attrs:{"color":"#184A8B","rounded":"","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleOpenBis()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#ffffff"}},[_vm._v(" mdi-volume-high ")]),_vm._v("\n            Ecouter le teaser\n          ")],1)],1)])])],1):_vm._e(),_vm._v(" "),(_vm.$route.path.includes('673e0938bdb12a3306c32308'))?_c('v-container',{staticClass:"pa-0"},[_c('v-sheet',{ref:"sheet",staticClass:"pa-2",class:{
        'v-sheet__desktop': _vm.$voicer.isDesktopOrTablet,
        'ma-3 mb-4': _vm.$voicer.isDesktopOrTablet,
      },attrs:{"color":"#F0F0F0"}},[_c('div',{staticClass:"text-alternate",class:{
          'body-2 font-weight-normal': _vm.$voicer.isDesktopOrTablet,
          'body-2': _vm.$voicer.isMobile,
        }},[_c('div',{staticClass:"pl-2 pr-2 pt-1"},[_vm._v("\n          Pour laisser une dédicace à votre équipe, cliquez sur le bouton\n          \"Enregistrer un message\" ou contactez-nous au 06 48 73 78 26\n          "),_c('br'),_vm._v(" "),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){$event.stopPropagation();_vm.showSecondFeedbackModal = true}}},[_vm._v("\n            Enregistrer un message\n          ")]),_vm._v(" "),_c('w-feedback-popup',{attrs:{"id":"secondFeedback"},model:{value:(_vm.showSecondFeedbackModal),callback:function ($$v) {_vm.showSecondFeedbackModal=$$v},expression:"showSecondFeedbackModal"}})],1)])])],1):_vm._e(),_vm._v(" "),_c('exc-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }